import * as React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { IconContainer, CircleSvg } from './SuccessAnimated'

const drawFirstLine = keyframes`
    0% {
        stroke-dasharray: 0,56;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 0,56;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 56,330;
        stroke-dashoffset: 0;
    }
`

const drawSecondLine = keyframes`
   0% {
        stroke-dasharray: 0,55;
        stroke-dashoffset: 1;
    }

    50% {
        stroke-dasharray: 0,55;
        stroke-dashoffset: 1;
    }

    100% {
        stroke-dasharray: 55,0;
        stroke-dashoffset: 70;
    }
`

const animationDrawFirstLine = css`
  animation: 0.7s ${drawFirstLine} ease-out;
`

const animationDrawSecondLine = css`
  animation: 0.7s ${drawSecondLine} ease-out;
`

const CrossSvg = styled.svg`
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 54px;
  left: 54px;
  width: 40px;
  height: 40px;

  .first-line {
    ${animationDrawFirstLine}
  }

  .second-line {
    ${animationDrawSecondLine}
  }
`

const FirstLine = styled.path`
  ${animationDrawFirstLine}
`

const SecondLine = styled.path`
  ${animationDrawSecondLine}
`

const ErrorAnimated = () => (
  <IconContainer>
    <CircleSvg className={'stroke-current text-red-600'}>
      <circle cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
    </CircleSvg>

    <CrossSvg className={'stroke-current text-red-600'}>
      <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
        <FirstLine d="M634.087,300.805L673.361,261.53" fill="none" />
      </g>

      <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
        <SecondLine d="M634.087,300.805L673.361,261.53" />
      </g>
    </CrossSvg>
  </IconContainer>
)

export default ErrorAnimated
