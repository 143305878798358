import * as React from 'react'
import { NakedLayout } from '../../components/Layout'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SuccessAnimated from '../../components/Icon/SuccessAnimated'
import ErrorAnimated from '../../components/Icon/ErrorAnimated'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import api from '../../apis'
import { navigate } from 'gatsby'
import ROUTES from '../../config/routes'

const StyledCloseButton = styled.button`
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
`

// see modal close button
const CloseButton = ({ onClick }) => {
  return (
    <StyledCloseButton onClick={onClick} className={'focus:outline-none mr-4 mt-4 lg:mr-8'}>
      <span
        className={
          'flex items-center justify-center border border-gray-200 dark:border-gray-700 rounded-full w-12 h-12'
        }
      >
        <FontAwesomeIcon
          className={'text-lg text-gray-600 dark:text-gray-200'}
          icon={['fal', 'times']}
        />
      </span>
    </StyledCloseButton>
  )
}

const ACTION_VERIFY_EMAIL = 'VERIFY_EMAIL'

const ConfirmPage = () => {
  const { t } = useTranslation('pages')

  const [isConfirmed, setIsConfirmed] = useState(null)

  const getGetParams = () => {
    const params = new URL(window.document.location).searchParams
    const action = params.get('action')
    const token = params.get('q')

    return { action, token }
  }

  useEffect(() => {
    const confirmRequest = async () => {
      const { action, token } = getGetParams()

      if (!action || !token) {
        setIsConfirmed(false)
        return
      }

      try {
        const { status } = await api.auth.postConfirm(action, token)
        setIsConfirmed(status === 204)
      } catch {
        setIsConfirmed(false)
      }
    }

    confirmRequest()
  }, [])

  const onContinueClick = () => {
    const { action } = getGetParams()

    let redirectRoute = ROUTES.home

    if (action === ACTION_VERIFY_EMAIL) {
      redirectRoute = ROUTES.dashboard
    }

    navigate(redirectRoute, { replace: true })
  }

  if (isConfirmed === null) {
    return null
  }

  return (
    <NakedLayout isHeightFull>
      <Helmet>
        <title>{t('confirm.seo.title')}</title>
      </Helmet>

      <CloseButton onClick={onContinueClick} />

      <div className={'w-full max-w-full m-0 p-0 shadow-none h-full bg-white dark:bg-gray-900'}>
        <div className={'max-w-lg mx-auto pt-16 sm:pt-32 px-6 text-center'}>
          {isConfirmed ? <SuccessAnimated /> : <ErrorAnimated />}

          <h1 className={'text-3xl mb-4 font-bold tracking-tight'}>
            {isConfirmed ? t('confirm.success.title') : t('confirm.error.title')}
          </h1>

          <p className={'text-gray-600 mb-6'}>
            {isConfirmed ? t('confirm.success.description') : t('confirm.error.description')}
          </p>

          <button onClick={onContinueClick} className={'btn btn-primary w-full max-w-xs'}>
            {t('confirm.continueButton')}
          </button>
        </div>
      </div>
    </NakedLayout>
  )
}

export default ConfirmPage
