import * as React from 'react'
import styled, { keyframes, css } from 'styled-components'

const drawCircle = keyframes`
    0% {
        stroke-dasharray: 0,330;
        stroke-dashoffset: 0;
        opacity: 1;
    }

    60% {
        stroke-dasharray: 330,330;
        stroke-dashoffset: 0;
        opacity: 0.6;
    }

    100%{
        opacity: 0.4;
    }
`

const drawCheck = keyframes`
   0% {
        stroke-dasharray: 49,80;
        stroke-dashoffset: 48;
        opacity: 0;
    }

    50% {
        stroke-dasharray: 49,80;
        stroke-dashoffset: 48;
        opacity: 1;
    }

    100% {
        stroke-dasharray: 130,80;
        stroke-dashoffset: 48;
    }
`

const animationCircleRule = css`
  animation: 0.7s ${drawCircle} ease-out;
`

const animationCheckRule = css`
  animation: 1s ${drawCheck} ease-out;
`

export const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 150px;
`

export const CircleSvg = styled.svg`
  > circle {
    stroke-dasharray: 330;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    opacity: 0.4;

    ${animationCircleRule}
  }
`

const CheckmarkSvg = styled.svg`
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 56px;
  left: 49px;
  width: 52px;
  height: 40px;

  path {
    ${animationCheckRule}
  }
`

const SuccessAnimated = () => (
  <IconContainer>
    <CircleSvg className={'stroke-current text-lime-600'}>
      <circle cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
    </CircleSvg>

    <CheckmarkSvg className={'stroke-current text-lime-600'}>
      <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
        <path fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
      </g>
    </CheckmarkSvg>
  </IconContainer>
)

export default SuccessAnimated
